import {NavigationService} from '../../services/navigation.service';
import {DispatchpanelComponent} from '../shared/dispatchpanel/dispatchpanel.component';
import {DriversComponent} from '../shared/drivers/drivers.component';
import {RideDetailsComponent} from './administration/rides/ride-details/ride-details.component';
import {ProductsComponent} from './settings/products/products.component';
import {PricingComponent} from './settings/pricing/pricing.component';
import {PricingRulesUpsertComponent} from './settings/pricing/parts/pricing-rules-upsert/pricing-rules-upsert.component';
import {DriverUpsertComponent} from './drivers/driver-upsert/driver-upsert.component';
import {SplitLayoutComponent} from '../layout/split-layout/split-layout.component';
import {CompanyResolver} from '../../resolvers/company-resolver';
import {DriverDetailsComponent} from './drivers/driver-details/driver-details.component';
import {RideLogComponent} from './administration/rides/ride-log/ride-log.component';
import {CloudConnectComponent} from './cloud-connect/cloud-connect.component';
import {RideDispatchLogComponent} from './administration/rides/ride-dispatch-log/ride-dispatch-log.component';
import {RideEditComponent} from '../shared/dispatchpanel/parts/ride-edit/ride-edit.component';
import {ProductUpsertComponent} from './settings/products/parts/product-upsert/product-upsert.component';
import {AppsComponent} from './settings/apps/apps.component';
import {AppsUpsertComponent} from './settings/apps/parts/apps-upsert/apps-upsert.component';
import {PricingDiscountsUpsertComponent} from 'app/components/company/settings/pricing/parts/pricing-discounts-upsert/pricing-discounts-upsert.component';
import {AuthGuard} from '../../guards/auth.guard';
import {LocationsComponent} from './settings/locations/locations.component';
import {LocationUpsertComponent} from './settings/locations/parts/location-upsert/location-upsert.component';
import {LocationImportComponent} from './settings/locations/parts/location-import/location-import.component';
import {CanDeactivateGuard} from '../../guards/can-deactivate-guard';
import {InvoicesComponent} from './administration/invoices/invoices.component';
import {LocationPoliciesComponent} from './settings/locations/parts/location-policies/location-policies.component';
import {LocationPolicyUpsertComponent} from './settings/locations/parts/location-policy-upsert/location-policy-upsert.component';
import {environment} from '../../../environments/environment';
import {DebtorDetailsComponent} from '../shared/debtors/debtor-details/debtor-details.component';
import {PassengerUpsertComponent} from '../shared/debtors/passenger-upsert/passenger-upsert.component';
import {RidesComponent} from '../shared/rides/rides.component';
import {RatingsComponent} from './ratings/ratings.component';
import {TranslateService} from '@ngx-translate/core';
import {ShiftsComponent} from '../shared/shifts/shifts.component';
import {ShiftDetailsComponent} from '../shared/shifts/parts/shift-details/shift-details.component';
import {DebtorsComponent} from './debtors/debtors.component';
import {PromotionsComponent} from './settings/promotions/promotions.component';
import {PromotionUpsertComponent} from './settings/promotions/parts/promotion-upsert/promotion-upsert.component';
import {CouponsComponent} from './settings/coupons/coupons.component';
import {CouponUpsertComponent} from './settings/coupons/parts/coupon-upsert/coupon-upsert.component';
import {CouponDetailsComponent} from './settings/coupons/parts/coupon-details/coupon-details.component';
import {GeneralComponent} from './settings/general/general.component';
import {WebbookerComponent} from './webbooker/webbooker.component';
import {WebUserUpsertComponent} from './drivers/webuser-upsert/webuser-upsert.component';
import {CustomerInvoicesComponent} from './administration/customer-invoices/customer-invoices.component';
import {InvoiceSettingsComponent} from './settings/invoice-settings/invoice-settings.component';
import {ContactDetailsComponent} from '../shared/debtors/contact-details/contact-details.component';
import {CustomerUpsertComponent} from './debtors/parts/customer-upsert/customer-upsert.component';
import {InstructionVideosComponent} from './about/instruction-videos/instruction-videos.component';
import {ChangeLogComponent} from './about/change-log/change-log.component';
import {PaymentOverviewComponent} from './administration/payment-overview/payment-overview.component';
import {OnboardingWizardComponent} from './onboarding-wizard/onboarding-wizard.component';
import {JobProcessComponent} from './settings/job-process/job-process.component';
import {AvailabilitySettingsComponent} from './settings/availability-settings/availability-settings.component';
import {MessageCenterComponent} from './settings/message-center/message-center.component';
import {MessageCenterRuleUpsertComponent} from './settings/message-center/parts/message-center-rule-upsert/message-center-rule-upsert.component';
import { ExportsComponent } from './administration/exports/exports.component';
import {ServiceAreaComponent} from './settings/service-area/service-area.component';
import {JobMetaFieldsComponent} from './settings/job-meta-fields/job-meta-fields.component';
import {JobMetaFieldUpsertComponent} from './settings/job-meta-fields/parts/job-meta-field-upsert/job-meta-field-upsert.component';

export class CompanyRoutes {
  static getBusinessSubRoutes: any[] = [
    {
      path: '',
      component: DispatchpanelComponent,
      showInMenu: false,
      label: 'menu_dispatchpanel',
      icon: 'send',
      runGuardsAndResolvers: true,
      context: 'company'
    },
    {
      path: 'dispatchpanel', component: DispatchpanelComponent, showInMenu: true, showMobile: true,
      label: 'menu_dispatchpanel', icon: 'send', context: 'company'
    },
    {path: 'dispatchpanel/:id/details', component: RideDetailsComponent, showInMenu: false, context: 'company'},
    {path: 'dispatchpanel/:action', component: RideEditComponent, showInMenu: false, context: 'company'},
    {path: 'dispatchpanel/:id/log', component: RideLogComponent, showInMenu: false, context: 'company'},
    {path: 'dispatchpanel/:id/edit', component: RideEditComponent, showInMenu: false, context: 'company'},
    {path: 'dispatchpanel/:id/:action', component: RideEditComponent, showInMenu: false, context: 'company'},
    {path: 'dispatchpanel/:id/dispatch-log', component: RideDispatchLogComponent, showInMenu: false, context: 'company'},

    {
      path: 'driverlist',
      component: DriversComponent,
      showInMenu: true,
      showMobile: true,
      label: 'members',
      icon: 'group',
      context: 'company'
    },
    {path: 'drivers', component: DriversComponent, showInMenu: false, showMobile: false, context: 'company'},
    {path: 'drivers/:id/details', component: DriverDetailsComponent, showInMenu: false, context: 'company'},
    {path: 'drivers/:action', component: DriverUpsertComponent, showInMenu: false, context: 'company'},
    {path: 'drivers/:id/shifts', component: ShiftsComponent, showInMenu: false, context: 'company'},
    {path: 'drivers/:id/shifts/:shiftId', component: ShiftDetailsComponent, showInMenu: false, context: 'company'},
    {path: 'drivers/:id/:action', component: DriverUpsertComponent, showInMenu: false, context: 'company'},

    {path: 'companyWebUsers/:action', component: WebUserUpsertComponent, showInMenu: false, context: 'company'},
    {path: 'companyWebUsers/:id/:action', component: WebUserUpsertComponent, showInMenu: false, context: 'company'},

    {
      path: 'jobs',
      showBottomInMenu: false,
      showMobile: true,
      showInMenu: true,
      label: 'administration',
      submenuName: 'administration',
      icon: 'assignment',
      context: 'company',
      component: RidesComponent,
      submenu: 'administration',
      children: [
        {
          path: 'jobs',
          component: RidesComponent,
          showInMenu: true,
          label: 'jobs',
          icon: 'account_balance',
          context: 'company'
        },
        {
          path: 'payment-overview',
          component: PaymentOverviewComponent,
          showInMenu: true,
          label: 'company_menu_payment_overview',
          icon: 'euro_symbol',
          context: 'company'
        },
        {
          path: 'customer-invoices',
          component: ProductsComponent,
          showInMenu: environment.showCompanyInvoices,
          label: 'company_menu_customer_invoices',
          icon: 'local_taxi',
          context: 'company'
        },
        {
          path: 'debtor-invoices',
          component: InvoiceSettingsComponent,
          showInMenu: environment.showCompanyInvoices,
          label: 'company_menu_driver_settlements',
          icon: 'receipt',
          context: 'company'
        },
        {
          path: 'exports',
          component: ExportsComponent,
          showInMenu: false,
          label: 'company_menu_exports',
          icon: 'download',
          context: 'company'
        },
      ]
    },

    {path: 'jobs', component: RidesComponent, showInMenu: false, showMobile: true, label: 'jobs', icon: 'assignment', context: 'company', submenu: 'administration'},
    {path: 'jobs/:id/details', component: RideDetailsComponent, showInMenu: false, context: 'company', submenu: 'administration'},
    {path: 'jobs/:action', component: RideEditComponent, showInMenu: false, context: 'company', submenu: 'administration'},
    {path: 'jobs/:id/log', component: RideLogComponent, showInMenu: false, context: 'company', submenu: 'administration'},
    {path: 'jobs/:id/edit', component: RideEditComponent, showInMenu: false, context: 'company', submenu: 'administration'},
    {path: 'jobs/:id/dispatch-log', component: RideDispatchLogComponent, showInMenu: false, context: 'company', submenu: 'administration'},

    {
      path: 'debtors',
      component: DebtorsComponent,
      showInMenu: true,
      showMobile: true,
      label: 'menu_customers',
      icon: 'business_center',
      context: 'company'
    },
    {path: 'debtors/tab/:tab', component: DebtorsComponent, context: 'company'},
    {path: 'debtors/:id/view', component: DebtorDetailsComponent, context: 'company'},
    {path: 'debtors/:debtorId/contacts/:id/:action', component: PassengerUpsertComponent, context: 'company'},
    {path: 'debtors/:debtorId/contacts/:action', component: PassengerUpsertComponent, context: 'company'},
    {path: 'debtors/contacts/:action', component: CustomerUpsertComponent, activeTab: 'contact', context: 'company'},
    {path: 'debtors/:id/:action', component: CustomerUpsertComponent, activeTab: 'customer', context: 'company'},
    {path: 'debtors/:action', component: CustomerUpsertComponent, context: 'company'},
    {path: 'contacts/:id/edit', component: CustomerUpsertComponent, activeTab: 'contact', context: 'company', action: 'edit'},
    {path: 'contacts/:id/:action', component: ContactDetailsComponent, context: 'company'},
    {
      path: 'customer-invoices',
      component: CustomerInvoicesComponent,
      label: 'company_menu_customer_invoices',
      icon: 'folder_shared',
      canDeactivate: [CanDeactivateGuard],
      submenu: 'administration'
    },
    {
      path: 'debtor-invoices',
      component: InvoicesComponent,
      label: 'company_menu_driver_settlements',
      icon: 'receipt',
      canDeactivate: [CanDeactivateGuard],
      submenu: 'administration'
    },
    {
      path: 'exports',
      component: ExportsComponent,
      label: 'company_menu_exports',
      icon: 'download',
      canDeactivate: [CanDeactivateGuard],
      submenu: 'administration'
    },
    {
      path: 'payment-overview',
      component: PaymentOverviewComponent,
      showInMenu: false,
      label: 'company_menu_payment_overview',
      icon: 'euro_symbol',
      submenu: 'administration',
    },

    {
      path: 'ratings',
      component: RatingsComponent,
      showInMenu: true,
      showMobile: false,
      label: 'ratings',
      icon: 'favorite',
      context: 'company'
    },

    {
      path: 'webbookers',
      component: WebbookerComponent,
      showInMenu: true,
      showMobile: false,
      label: 'webbooker',
      icon: 'computer',
      context: 'company',
      canActivate: [AuthGuard]
    },
    {path: 'webbookers/:id/:action', component: AppsUpsertComponent, showInMenu: false, canActivate: [AuthGuard], submenu: 'webbookers'},
    {
      path: 'general',
      showBottomInMenu: true,
      showMobile: false,
      label: 'settings',
      submenuName: 'settings',
      icon: 'settings',
      context: 'company',
      component: GeneralComponent,
      submenu: 'settings',
      runGuardsAndResolvers: 'always',
      children: [
        {
          path: 'general',
          component: GeneralComponent,
          showInMenu: true,
          label: 'menu_general',
          icon: 'account_balance',
          context: 'company',
          runGuardsAndResolvers: 'always',
        },
        {
          path: 'availability',
          component: AvailabilitySettingsComponent,
          showInMenu: true,
          label: 'availability',
          icon: 'timer',
          context: 'company',
          runGuardsAndResolvers: 'always',
        },
        {
          path: 'servicearea',
          component: ServiceAreaComponent,
          showInMenu: true,
          label: 'servicearea',
          icon: 'map',
          context: 'company',
        },
        {
          path: 'message-center',
          component: MessageCenterComponent,
          showInMenu: true,
          label: 'message_center',
          icon: 'email',
          context: 'company'
        },
        {
          path: 'job-process',
          component: JobProcessComponent,
          showInMenu: true,
          label: 'cancellations',
          icon: 'memory',
          context: 'company'},
        {
          path: 'products',
          component: ProductsComponent,
          showInMenu: true,
          label: 'products',
          icon: 'local_taxi',
          context: 'company'
        },
        {
          path: 'job-meta-fields',
          component: JobMetaFieldsComponent,
          showInMenu: true,
          label: 'job_meta_field',
          icon: 'insert_drive_file',
          context: 'company'
        },
        {
          path: 'invoice-settings',
          component: InvoiceSettingsComponent,
          showInMenu: true,
          label: 'invoicing',
          icon: 'receipt',
          context: 'company'
        },
        {
          path: 'pricing',
          component: PricingComponent,
          showInMenu: true,
          label: 'company_menu_pricing',
          icon: 'euro_symbol',
          context: 'company'
        },
        {
          path: 'locations',
          component: LocationsComponent,
          showInMenu: true,
          label: 'company_menu_locations',
          icon: 'map',
          context: 'company'
        },
        {
          path: 'location-policies',
          component: LocationPoliciesComponent,
          showInMenu: true,
          label: 'company_menu_location_policies',
          icon: 'beenhere',
          context: 'company'
        },
        {path: 'coupons', component: CouponsComponent, showInMenu: true, label: 'coupons', icon: 'redeem', context: 'company'},
        {
          path: 'promotions',
          component: ProductsComponent,
          showInMenu: true,
          label: 'promotions',
          icon: 'event',
          context: 'company',
          requiredProduct: true
        },
        {path: 'apps', component: AppsComponent, showInMenu: true, label: 'company_menu_apps', icon: 'apps', canActivate: [AuthGuard]},
      ]
    },
    {
      path: 'apps',
      component: AppsComponent,
      showInMenu: false,
      label: 'company_menu_apps',
      icon: 'apps',
      submenu: 'settings',
      canActivate: [AuthGuard]
    },
    {
      path: 'apps/:id/:action',
      component: AppsUpsertComponent,
      context: 'company',
      submenu: 'settings',
      canActivate: [AuthGuard]
    },

    {
      path: 'invoice-settings',
      component: InvoiceSettingsComponent,
      showInMenu: false,
      label: 'invoicing',
      icon: 'local_taxi',
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'job-process',
      component: JobProcessComponent,
      showInMenu: false,
      label: 'jobprocess',
      icon: 'event',
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'availability',
      component: AvailabilitySettingsComponent,
      showInMenu: false,
      label: 'jobprocess',
      icon: 'event',
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'servicearea',
      component: ServiceAreaComponent,
      showInMenu: false,
      canDeactivate: [CanDeactivateGuard],
      label: 'servicearea',
      icon: 'map',
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'message-center',
      component: MessageCenterComponent,
      showInMenu: false,
      label: 'jobprocess',
      icon: 'event',
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'message-center/JobMessageAutomationRule/:action',
      component: MessageCenterRuleUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'message-center/JobMessageAutomationRule/:id/:action',
      component: MessageCenterRuleUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings',
      canActivate: [AuthGuard]
    },
    {
      path: 'products',
      component: ProductsComponent,
      showInMenu: false,
      label: 'products',
      icon: 'local_taxi',
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'products/:action',
      component: ProductUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'products/:id/:action',
      component: ProductUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'job-meta-fields',
      component: JobMetaFieldsComponent,
      label: 'job_meta_field',
      icon: 'insert_drive_file',
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'job-meta-fields/:action',
      component: JobMetaFieldUpsertComponent,
      label: 'job_meta_field',
      icon: 'insert_drive_file',
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'job-meta-fields/:id/:action',
      component: JobMetaFieldUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'pricing', component: PricingComponent, showInMenu: false, label: 'company_menu_pricing',
      icon: 'euro_symbol', context: 'company', submenu: 'settings'
    },
    {
      path: 'pricing/:model',
      component: PricingComponent,
      label: 'company_menu_pricing',
      context: 'company',
      submenu: 'settings',
      canActivate: [AuthGuard]
    },
    {
      path: 'pricing/rules/:action',
      component: PricingRulesUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings',
      canActivate: [AuthGuard]
    },
    {
      path: 'pricing/rules/:id/:action',
      component: PricingRulesUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings',
      canActivate: [AuthGuard]
    },
    {
      path: 'pricing/special-rates/:action',
      component: PricingDiscountsUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company', submenu: 'settings', canActivate: [AuthGuard]
    },
    {
      path: 'pricing/special-rates/:id/:action',
      component: PricingDiscountsUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company', submenu: 'settings', canActivate: [AuthGuard]
    },
    {
      path: 'locations', component: LocationsComponent, showInMenu: false,
      label: 'company_menu_locations', icon: 'map', context: 'company', submenu: 'settings'
    },
    {
      path: 'locations/:model/:action',
      component: LocationUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings'
    },
    {path: 'locations/:model/:id/import', component: LocationImportComponent, context: 'company', submenu: 'settings'},
    {
      path: 'locations/:model/:id/:action',
      component: LocationUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings'
    },
    {path: 'location-policies/:action', component: LocationPolicyUpsertComponent, context: 'company', submenu: 'settings'},
    {path: 'location-policies/:id/:action', component: LocationPolicyUpsertComponent, context: 'company', submenu: 'settings'},
    {
      path: 'location-policies',
      component: LocationPoliciesComponent,
      showInMenu: false,
      label: 'company_menu_location_policies',
      icon: 'beenhere',
      context: 'company', submenu: 'settings'
    },
    {
      path: 'coupons',
      component: CouponsComponent,
      showInMenu: false,
      label: 'coupons',
      icon: 'redeem',
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'coupons/:id/details',
      component: CouponDetailsComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'coupons/:action',
      component: CouponUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'coupons/:id/:action',
      component: CouponUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings'
    },

    {
      path: 'promotions',
      component: PromotionsComponent,
      showInMenu: false,
      label: 'promotions',
      icon: 'event',
      context: 'company',
      requiredProduct: true,
      submenu: 'settings'
    },
    {
      path: 'promotions/:action',
      component: PromotionUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'promotions/:id/:action',
      component: PromotionUpsertComponent,
      canDeactivate: [CanDeactivateGuard],
      context: 'company',
      submenu: 'settings'
    },
    {
      path: 'general',
      component: GeneralComponent,
      showInMenu: false,
      label: 'menu_general',
      icon: 'account_balance',
      context: 'company',
      submenu: 'settings'
    },
    {path: 'general/:result', component: GeneralComponent, context: 'company', submenu: 'settings'},
    {
      path: 'license/:action', component: CloudConnectComponent, showInMenu: false,
      label: 'menu_cloud_connect', icon: 'cloud', context: 'company'
    },
    {
      path: 'contract',
      component: CloudConnectComponent,
      showBottomInMenu: true,
      showMobile: true,
      label: 'menu_cloud_connect',
      icon: 'cloud',
      context: 'company'
    },
    {
      path: 'contract/:action', component: CloudConnectComponent, showInMenu: false,
      label: 'menu_cloud_connect', icon: 'cloud', context: 'company'
    },
    {
      path: 'about',
      showBottomInMenu: true,
      showMobile: true,
      label: 'help',
      submenuName: 'about',
      icon: 'help',
      context: 'company',
      children: [
        {
          path: 'instructions',
          showMobile: true,
          component: GeneralComponent,
          showInMenu: true,
          label: 'menu_video',
          icon: 'videocam',
          context: 'company'
        },
        {
          path: 'features',
          showMobile: true,
          component: ProductsComponent,
          showInMenu: true,
          label: 'changelog',
          icon: 'code',
          context: 'company'
        },
        {
          path: 'url',
          newTabUrl: 'https://intercom.help/yourdriverapp/en/collections/8346928-fleet-solutions',
          target: '_new',
          showMobile: true,
          component: ProductsComponent,
          showInMenu: true,
          label: 'helpcenter',
          icon: 'info',
          context: 'company',
          submenu: 'about'
        }
      ]
    },
    {
      path: 'instructions',
      component: InstructionVideosComponent,
      showInMenu: false,
      label: 'menu_video',
      icon: 'videocam',
      context: 'company',
      submenu: 'about'
    },
    {
      path: 'features',
      component: ChangeLogComponent,
      showInMenu: false,
      label: 'change_log',
      icon: 'code',
      context: 'company',
      submenu: 'about'
    },
    {
      path: 'onboarding-wizard',
      component: OnboardingWizardComponent,
      canActivate: [AuthGuard],
      showInMenu: false,
      label: 'wizard',
      icon: 'code',
      context: 'company',
    },
  ];

  static getInjectRoutes(): any[] {
    return [
      {
        path: 'groups/:id',
        icon: 'assignment',
        group: 'groups',
        label: 'My shifts',
        canActivate: [AuthGuard],
        sort: 2,
        showInMenu: false,
        component: SplitLayoutComponent,
        resolve: {
          currentRoute: NavigationService,
          company: CompanyResolver
        },
        children: CompanyRoutes.getBusinessSubRoutes
      }
    ];
  }

  static getRoutes(_translateService: TranslateService, index, company, type = 'driver') {
    return new Promise((resolve) => {
      return _translateService.get(['company_menu_groups']
      ).subscribe((translations: string[]) => {
        resolve(
          {
            path: (type === 'dispatchgroup' ? `dispatchgroup/${company.id}/drivers` :
              (type === 'CompanyWebUser' ? `CompanyWebUser/${company.id}/dispatchpanel` : `groups/${company.id}/dispatchpanel`)),
            icon: 'business',
            avatar: (company.avatar && company.avatar.smallRounded ? company.avatar.smallRounded : ''),
            group: translations['company_menu_groups'],
            label: company.name,
            canActivate: [AuthGuard],
            sort: index,
            showInMenu: true
          }
        );
      });
    });
  }
}
